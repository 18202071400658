<template>
  <div></div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Index',
  methods: {
    ...mapMutations(['SET_LOADING_DIALOG_VISIBILITY', 'SET_START_BUTTON_DIALOG'])
  },
  mounted() {
    let finish_loading = false
    // // Init the Greeting Controller
    const loading_monitor = setInterval(() => {
      // Check whether the Live2D Model is still loading
      if (!finish_loading) {
        this.SET_LOADING_DIALOG_VISIBILITY(true)
      } else {
        clearInterval(loading_monitor)
      }
    }, 250)

    // Timer for ensure the live2d module has enough time to load
    setTimeout(() => {
      finish_loading = true
      this.SET_LOADING_DIALOG_VISIBILITY(false)
      this.SET_START_BUTTON_DIALOG(true) // Show the start button for initializing first touch interaction event
    }, 1000)
  }
}
</script>
